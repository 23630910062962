.unit-counter {
  &.counter-info {
    width: 100%;
    height: 100%;
    margin: 20px 0 0;
    @media screen and (min-width: 768px) {
      margin: 0;
    }

    .counter-info-icon {
      width: 30px;
      height: auto;
      display: block;
      @media screen and (min-width: 768px) {
        width: 38px;
      }

      path {
        fill: var(--green-dark-100);
      }
    }

    .counter-info-title {
      font-family: "UnimedSlab-2020-Bold", Arial, sans-serif;
      font-size: 1.444rem;
      display: block;
      color: var(--green-dark-100);
      margin: 10px 0 0;
      line-height: 1.6;
      @media screen and (min-width: 768px) {
        margin: 20px 0 0;
      }
    }

    .counter-info-text {
      font-family: "UnimedSans-2020-Book", Arial, sans-serif;
      font-size: 1rem;
      line-height: 1.39;
      color: var(--green-dark-100);
      margin-top: 3px;
    }
  }
}
